<template lang="pug">
  el-col(:span="14")
      el-row
          el-col(:span="12")
              div#areaChart.chart
          el-col(:span="12")
              div#channelChart.chart

</template>

<script>
var echarts = require("echarts");
import { getWidget } from "@/api/dashboard";
const id = "D4";
export default {
  name: id,
  async created() {
    const resp = await getWidget(id);
    const { order_cnt_by_province, order_cnt_by_shop } = resp.data.data;

    // 地区饼图
    const areaChart = echarts.init(document.getElementById("areaChart"));
    areaChart.setOption({
      title: { text: "30天订单地区分布" },
      tooltip: {
        show: true
      },
      legend: {
        data: ["地区分布"],
        right: 1,
        color: "#20B2AA"
      },
      series: [
        {
          type: "pie",
          name: "地区分布",
          data: order_cnt_by_province
        }
      ]
    });

    // 渠道饼图
    // eslint-disable-next-line prettier/prettier
      const channelChart = echarts.init(document.getElementById("channelChart"));
    channelChart.setOption({
      title: { text: "30天订单渠道分布" },
      tooltip: {
        show: true
      },
      legend: {
        data: ["渠道分布"],
        right: 1,
        color: "#20B2AA"
      },
      series: [
        {
          type: "pie",
          name: "地区分布",
          data: order_cnt_by_shop
        }
      ]
    });
  }
};
</script>

<style lang="scss" scoped>
.chart {
  height: 400px;
  padding: 30px;
}
</style>
